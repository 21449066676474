@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: theme(colors.gray.100);
  color: theme(colors.gray.900);
  font-family: 'Hiragino Sans', 'ヒラギノ角ゴシック', 'Meiryo', 'メイリオ', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
